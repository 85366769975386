<template>

	<layout :include_header="true">

		<template v-slot:header-end>

			

		</template>

		<template v-slot:main-body>
			
			<div class="w-100 h-100 d-flex align-items-center justify-content-center">
				<span class="spinner-border" role="status">
					<span class="visually-hidden">Loading...</span>
				</span>
			</div>

		</template>

	</layout>

</template>

<script>
import { mapGetters } from 'vuex';
import layout from '@/components/layout'
import user_service from '@/services/user_service'

export default {
	name: 'logout',

	components: {
		layout,
	},

	data() {
		return {
			loading: false,
			logout_loading: true,
			error: {}
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

	},

	mounted()
	{
		this.logout()

		this.reset_error()
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async logout()
		{
			this.logout_loading = true 

			this.reset_error()

			user_service.logout().then(() => {
				this.$store.commit( 'user/SET_USER', null )
				this.$router.push( { name: 'login' } )
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.logout_loading = false
			})
		},
	}
}
</script>

<style>

</style>
